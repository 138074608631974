export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'text',
      value: (e) => e.name,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Model',
    },
    cell: {
      type: 'text',
      value: (e) => e.model,
      tooltip: true,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'User id',
    },
    cell: {
      type: 'slot',
      name: 'userId',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'UUID',
    },
    cell: {
      type: 'text',
      value: (e) => e.uuid,
      tooltip: true,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'QR',
    },
    cell: {
      type: 'text',
      value: (e) => e.qr,
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'text',
      value: (e) => e.created,
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Updated',
    },
    cell: {
      type: 'slot',
      name: 'updated',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import User from '~/components/User.vue';
import constant from '~/const';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { Filters, Modal, User },

  data() {
    return {
      editingDevice: {
        show: false,
        data: {},
        isCreating: false,
      },
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersDevices', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersDevices', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      deleteDeviceAction: 'deleteDevice',
      editDeviceAction: 'editDevice',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    getStatusColor(id) {
      switch (id) {
        case 0:
          return 'success--text';
        case 1:
          return 'warning--text';
        case 2:
          return 'error--text';
        default:
          return '';
      }
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      if (this.$refs.deviceTable?.$el?.scrollIntoView) {
        this.$refs.deviceTable.$el.scrollIntoView();
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openModalEditDevice(row) {
      this.editingDevice.show = true;
      this.editingDevice.data = { ...row };
      this.editingDevice.isCreating = false;
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingDevice;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create device?'
          : 'Are you sure you want to edit device?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              // There is still no POST method of creating device
              // await this.*DeviceAction(input)
            } else {
              await this.editDeviceAction(input);
            }
            this.editingDevice.show = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Device created');
            } else {
              this.setSuccessNotification('Device edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    deleteDevice(row) {
      this.confirmAction({
        title: `Are you sure you want to delete device with id: ${row.id}?`,
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.deleteDeviceAction({ id: row.id, userId: row.userId });
            await this.loadDataAction({});
            this.setSuccessNotification(`Device ${row.id} deleted`);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      input: {},
      constant,
      currentFields: [],
      isCollapsed: true,
      maxLength: 20,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersPaymentMethods', { p2pPaymentMethods: 'data' }),
    ...mapGetters('tradersPaymentMethods', ['getMethodById']),

    codeStyle() {
      return {
        backgroundColor: '#4a4a4a',
        color: '#ffffff',
        cursor: 'pointer',
        display: 'block',
        padding: '10px',
        borderRadius: '4px',
      };
    },

    preStyle() {
      return {
        whiteSpace: 'pre-wrap', // позволяет переносить длинные строки
        wordBreak: 'break-all', // переносит слова, если они слишком длинные
      };
    },

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    additionalFieldsForCreatingInput() {
      return {
        userId: '',
        name: '',
        linkToOffer: false,
        deviceId: '',
        enableSell: true,
        enableBuy: true,
      };
    },
    defaultEditingInput() {
      return {
        paymentMethodId: 1,
        currency: 'RUB',
        cardNumber: '',
        cardHolder: '',
        dayLimit: 0,
        weekLimit: 0,
        monthLimit: 0,
        minLimit: 0,
        dayOperationLimit: 0,
        weekOperationLimit: 0,
        monthOperationLimit: 0,
        status: constant.traders.REQUISITE_TYPE_IOTA[constant.traders.REQUISITE_TYPE.REQUISITE_STATUS_ENABLED],
      };
    },
    isApplyPossible() {
      return this.input?.userId && this.input?.name;
    },
  },

  watch: {
    value(val) {
      if (val) {
        const {
          isCreating, defaultEditingInput, additionalFieldsForCreatingInput, data,
        } = this;
        if (isCreating) {
          this.$set(this, 'input', { ...additionalFieldsForCreatingInput, ...defaultEditingInput });
        } else {
          const model = { requisitesId: data.id };
          Object.keys(defaultEditingInput).forEach((key) => {
            model[key] = data[key];
            model.paymentMethodId = data.paymentMethod.id;
          });
          this.$set(this, 'input', model);
          this.currentFields = data.paymentMethod.fields;
        }
      }
    },
  },

  methods: {
    ...mapActions('common', [
      'setErrorNotification',
      'setGeneralLoader',
    ]),
    ...mapActions('templates', {
      loadTemplates: 'loadData',
    }),

    async onPaymentMethodChange() {
      this.setGeneralLoader(true);
      await this.loadTemplates();
      const selectedMethod = this.getMethodById(this.input.paymentMethodId);
      if (selectedMethod && selectedMethod.fields) {
        this.currentFields = selectedMethod.fields;
      } else {
        this.currentFields = this.$props.data.paymentMethod.fields || [];
      }
      this.setGeneralLoader(false);
    },

    toggleRegex() {
      this.isCollapsed = !this.isCollapsed;
    },

    formattedRegex(regex) {
      return regex.replace(/(.{50})/g, '$1\n');
    },

    collapsedRegex(regex) {
      return regex.length > this.maxLength
        ? `${regex.slice(0, this.maxLength)}...`
        : regex;
    },

    apply() {
      const { input } = this;
      if (
        input.dayLimit <= 0
        || input.dayOperationLimit <= 0
        || input.minLimit <= 0
        || input.monthLimit <= 0
        || input.monthOperationLimit <= 0
        || input.weekLimit <= 0
        || input.weekOperationLimit <= 0) {
        this.setErrorNotification('Limits can not equals zero');
      } else {
        this.$emit('apply', { input });
      }
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('div',{staticClass:"ml-10 mt-3"},[_c('v-btn',{attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":_vm.openCreateRequisiteModal}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1)],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}},{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"userId",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":Number(row.userId),"username":row.username}})]}},{key:"deviceId",fn:function(ref){
var row = ref.row;
return [(row.deviceId)?_c('span',{staticStyle:{"cursor":"pointer","color":"var(--v-anchor-base)","text-decoration":"underline"},on:{"click":function($event){$event.stopPropagation();return _vm.openDeviceById(row.deviceId)}}},[_vm._v(" "+_vm._s(row.deviceId)+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('No device')))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.statusClass(row.status)},[_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.REQUISITE_TYPE_IOTA[row.status]))+" ")])]}},{key:"paymentMethodStatus",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.paymentMethodStatusClass(row.paymentMethod ? row.paymentMethod.status : '')},[_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.PAYMENT_METHOD_STATUS_LIST[row.paymentMethod.status].caption))+" ")])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.openModalEditRequisite(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteRequisite(row)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('Modal',{attrs:{"data":_vm.editingRequisite.data,"is-creating":_vm.editingRequisite.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingRequisite.show),callback:function ($$v) {_vm.$set(_vm.editingRequisite, "show", $$v)},expression:"editingRequisite.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }